
import legendItemsPresence from "../entities/PresenceLegendItems";
import { features } from "../data/countries.json";
import axios from "axios";
class LoadCountryTaskAssembly {
  baseUrl = "https://backend.africanagora.org/api/nation/"
  totalScore = 0
  fairScore = 0
  setState = null;

  load = (setState) => {
    this.setState = setState;

    axios.get(`${this.baseUrl}`)
    .then(res => {
      this.#processAgoraData(res.data)     
    })
  };

  #processAgoraData = (agoraCountries) => {
    for (let i = 0; i < features.length; i++) {
      const country = features[i];
      const agoraCountry = agoraCountries.find(
        (agoraCountry) => country.properties.ISO_A3 === agoraCountry.ISO_A3
      );
      
        country.properties.totalScore = 0;
        country.properties.confirmedText = 0;
        country.properties.Presence_of_Laws_that_affect_CSOs = 0;
        country.properties.Ease_of_Moving_Funds = 0;
        country.properties.Ease_of_Registration = 0;
        country.properties.Ease_of_fundraising_from_external_sources = 0;
        country.properties.Freedom_of_Expression  = 0;
        country.properties.Freedom_of_Assembly = 0;
      

      if (agoraCountry != null) {
      
        country.properties.Presence_of_Laws_that_affect_CSOs = Number(agoraCountry.Presence_of_Laws_that_affect_CSOs);
        country.properties.Ease_of_Moving_Funds = Number(agoraCountry.Ease_of_Moving_Funds);
        country.properties.Ease_of_Registration = Number(agoraCountry.Ease_of_Registration);
        country.properties.Ease_of_fundraising_from_external_sources = Number(agoraCountry.Ease_of_fundraising_from_external_sources);
        country.properties.Freedom_of_Expression  = Number(agoraCountry.Freedom_of_Expression);
        country.properties.Freedom_of_Assembly = Number(agoraCountry.Freedom_of_Assembly);
        this.fairScore = Number(country.properties.Presence_of_Laws_that_affect_CSOs);
      }
      this.#setCountryColor(country);
    }

    this.setState(features);
  };

  #setCountryColor = (country) => {
    const legendItem = legendItemsPresence.find((item) =>
      item.isFor(Number(this.fairScore))
    );

    if (legendItem != null) country.properties.color = legendItem.color;
  };

  #formatNumberWithCommas = (number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
}
export default LoadCountryTaskAssembly;
