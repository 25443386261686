import React, { useState, useEffect } from "react";
import Loading from "../components/Loading";
import ExpressionMap from "../components/ExpressionMap";
import LoadCountriesTask from "../tasks/LoadCountriesTaskExpression";
import ReactGA from 'react-ga';

const TRACKING_ID ="G-2D7RRR14SS";
ReactGA.initialize(TRACKING_ID);
ReactGA.pageview(window.location.pathname + window.location.search);


function FreedomOfExpression() {
  const [countries, setCountries] = useState([]);
  const load = () => {
    console.log("load");
    const loadCountriesTask = new LoadCountriesTask();
    loadCountriesTask.load((countries) => setCountries(countries));
  };
  console.log(countries)

  useEffect(load, []);

  return (
    <div>
      {countries.length === 0 ? (
        <Loading />
      ) : (
        <div >
          <ExpressionMap
 countries={countries} />
        </div>
      )}
    </div>
  );
};

export default FreedomOfExpression