import React, { useState, useEffect } from "react";

import Loading from "../components/Loading";
import AssemblyMap from "../components/AssemblyMap";
import LoadCountriesTaskAssembly from "../tasks/LoadCountriesTaskAssembly";
import Legend from "../components/Legend";
import legendItems from "../entities/LegendItems";

import ReactGA from 'react-ga';

const TRACKING_ID ="G-2D7RRR14SS";
ReactGA.initialize(TRACKING_ID);
ReactGA.pageview(window.location.pathname + window.location.search);


function FreedomOfAssembly() {
  const [countries, setCountries] = useState([]);

  const legendItemsReverse = [...legendItems].reverse();

  const load = () => {
    console.log("load");
    const loadCountriesTask = new LoadCountriesTaskAssembly();
    loadCountriesTask.load((countries) => setCountries(countries));
  };
  console.log(countries)

  useEffect(load, []);

  return (
    <div>
      {countries.length === 0 ? (
        <Loading />
      ) : (
        <div >
          <AssemblyMap
 countries={countries} />
        </div>
      )}
    </div>
  );
};

export default FreedomOfAssembly