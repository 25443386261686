import React from 'react'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import {Link} from 'react-router-dom';
import './NavTailwind.css';

  function NavTailwind() {
    return (
      <Navbar collapseOnSelect expand="lg" style={{display:'inline',height:"80px"}}>
        <Container style={{marginLeft:"30px", paddingRight:"40px"}}>
          <Navbar.Brand><Link to="/">
          <img src={require("../images/agora-logo.png")} width="150px"/>
            </Link></Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link><Link to="/african-agora">
              About The African Agora
            </Link>
            </Nav.Link>
            </Nav>

            <Nav className="me-auto">
              <Nav.Link><Link to="/presence-of-laws-that-affect-cso">
              Presence Of Laws That Affect CSOs
            </Link>
            </Nav.Link>
            </Nav>

            <Nav className="me-auto">
              <Nav.Link><Link to="/ease-of-registration">
              Ease Of Registration
            </Link>
            </Nav.Link>
            </Nav>

            <Nav className="me-auto">
              <Nav.Link><Link to="/ease-of-fundraising">
              Ease Of Fundraising
            </Link>
            </Nav.Link>
            </Nav>

            <Nav className="me-auto">
              <Nav.Link><Link to="/ease-of-moving-funds">
              Ease Of Moving Funds
            </Link>
            </Nav.Link>
            </Nav>

            <Nav className="me-auto">
              <Nav.Link><Link to="/freedom-of-assembly">
              Freedom Of Assembly
            </Link>
            </Nav.Link>
            </Nav>

            <Nav className="me-auto">
              <Nav.Link><Link to="/freedom-of-expression">
              Freedom Of Expression
            </Link>
            </Nav.Link>
            </Nav>

            <Nav className="me-auto">
              <Nav.Link><Link to="/navigation">
              Navigation
            </Link>
            </Nav.Link>
            </Nav>
            
          </Navbar.Collapse>
        </Container>
      </Navbar>
    );
  }
  

export default NavTailwind;