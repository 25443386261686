import React, {useEffect, useState} from "react";
import { Map, GeoJSON } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import "./AgoraMap.css";
import KeyCard from "./KeyCard";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import axios from "axios";
import Moment from 'react-moment';
import Loading from "./Loading";
import ReactGA from 'react-ga';

const TRACKING_ID ="UA-121872886-8";
ReactGA.initialize(TRACKING_ID);
ReactGA.pageview(window.location.pathname + window.location.search);


const AgoraMap = ({ countries }) => {
  const mapStyle = {
    weight: 1,
    color: "black",
    fillOpacity: 1
  };

  const history = useNavigate();


  const onNavigateCountry = (id) => {
    console.log(id.layer.feature.properties.ISO_A3)
    history(`details/${id.layer.feature.properties.ISO_A3}`)
  }  

  const [news, setNews] = useState([]);
   let newsData = [];
  const apiKey = "a61811669f1e4c3fb4123f0ef0f554f9";
  const liveUrl = `https://newsapi.org/v2/everything?q=civil society&sortBy=relevancy&domains=allafrica.com,news24.com&apiKey=${apiKey}`;
  const mainUrl = "https://backend.africanagora.org/api/news/";
  const [reports, setReports] = useState([]);

  const fetchNews = async () => {
    try {
      const res = await axios.get(
        mainUrl
      );
      newsData = res.data;
      setNews(newsData.reverse());
      //setAllData(newsData.slice(0, 4))
    } catch (err) {
      console.error(err);
    }
  };

  const fetchReports = async () => {
    try {
      const res = await axios.get('https://backend.africanagora.org/api/report/');
      console.log(res);
      setReports(res.data);
      //setAllData(newsData.slice(0, 4))
    } catch (err) {
      console.error(err);
    }
  };


  useEffect(() => {
    fetchReports();
    fetchNews();
  }, []);

  const onEachCountry = (country, layer) => {
    layer.options.fillColor = country.properties.color;

const name = country.properties.ADMIN;
const confirmedText = country.properties.confirmedText;

const presence = country.properties.Presence_of_Laws_that_affect_CSOs;
  const easeOfMovingFunds = country.properties.Ease_of_Moving_Funds;
    const easeOfRegistration = country.properties.Ease_of_Registration;
    const easeOfFundraising = country.properties.Ease_of_fundraising_from_external_sources;
    const freedomOfExpression = country.properties.Freedom_of_Expression;
    const freedomOfAssembly = country.properties.Freedom_of_Assembly;
    const totalScore = presence+easeOfMovingFunds+easeOfRegistration+easeOfFundraising+freedomOfExpression+freedomOfAssembly;
    const averageScore = Math.round(totalScore/6);
    
    layer.bindTooltip(`<h1 style='margin-top:10px'>Country: ${name}</h1>
    <h3>Overall Category: ${averageScore===1?"Open":averageScore===2?"Narrow":averageScore===3?"Obstructed":averageScore===4?"Repressed":"Closed"} </h3>
    <h4>Thematic Areas</h4>
    <p>Presence of Laws that affect CSOs: ${presence===1?"Open":presence===2?"Narrow":presence===3?"Obstructed":presence===4?"Repressed":"Closed"}</p>
      <p>Ease of Registration: ${easeOfRegistration===1?"Open":easeOfRegistration===2?"Narrow":easeOfRegistration===3?"Obstructed":easeOfRegistration===4?"Repressed":"Closed"}</p>
      <p>Ease of Moving Funds: ${easeOfMovingFunds===1?"Open":easeOfMovingFunds===2?"Narrow":easeOfMovingFunds===3?"Obstructed":easeOfMovingFunds===4?"Repressed":"Closed"}</p>
      <p>Ease of Fundraising from External Sources: ${easeOfFundraising===1?"Open":easeOfFundraising===2?"Narrow":easeOfFundraising===3?"Obstructed":easeOfFundraising===4?"Repressed":"Closed"}</p>
      <p>Freedom of Assembly: ${freedomOfAssembly===1?"Open":freedomOfAssembly===2?"Narrow":freedomOfAssembly===3?"Obstructed":freedomOfAssembly===4?"Repressed":"Closed"}</p> 
      <p>Freedom of Expression: ${freedomOfExpression===1?"Open":freedomOfExpression===2?"Narrow":freedomOfExpression===3?"Obstructed":freedomOfExpression===4?"Repressed":"Closed"}</p>
      `, { permanent: false, offset: [0, 12] });
//layer.bindPopup(`${name} ${presence}`);

};

  return (
    <>
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb" style={{ backgroundColor: "white" }}>
          <li
            className="breadcrumb-item active"
            style={{ fontSize: "20px", fontWeight: "bold" }}
            aria-current="page"
          >
            African AGORA / Home
          </li>
        </ol>
      </nav>
      <ol className="breadcrumb" style={{ backgroundColor: "white" }}>
        <li
          className="breadcrumb-item active"
          style={{ fontSize: "20px", fontWeight: "bold", color: "#374c5b" }}
        >
          Tracking the Shrinking Civic Space in SADC
        </li>
      </ol>

      <div className="row">
        <div className="col-lg-2 col-xs-12">
          <div style={{ width: "20rem" }}>
            <ul className="list-group list-group-flush">
              <li
                className="list-group-item"
                style={{
                  backgroundColor: "white",
                  fontStyle: "italic",
                  fontFamily: "sans-serif",
                  fontSize: "14px",
                }}
              >
                The term “Agora” is derived from an ancient Greek term and can
                be used to mean “public space”; “assembly”; or “gathering
                place”. The African Agora is a platform that seeks to provide an
                overview of the state of the public space in Africa.
              </li>
              <Link
                className="btn"
                style={{
                  backgroundColor: "#374C5B",
                  color: "white important",
                  fontSize: "15px",
                  fontStyle: "italic",
                }}
                to="/african-agora"
              >
                Read more
              </Link>
            </ul>
          </div>
          <div
            style={{
              fontWeight: "bolder",
              width: "12rem",
              marginTop: "40px",
              marginLeft: "20px",
            }}
          >
            <KeyCard />
          </div>
        </div>
        <div
          className="col-lg-6 col-xs-12 col-centered"
          style={{ marginLeft: "auto" }}
        >
          <Map
            style={{ height: "140vh" }}
            zoom={4}
            center={[-35, 25]}
            dragging={false}
          >
            <GeoJSON
              style={mapStyle}
              data={countries}
              onEachFeature={onEachCountry}
              onclick={onNavigateCountry}
            />
          </Map>
        </div>

        <div className="col-lg-3 col-xs-12">
          <h4>News from the Region</h4>
          {news.length === 0 ? (
            <Loading />
          ) : (
            <ul className="timeline">
              {news.map((newsItem) => (
                <li key={newsItem?.id}>
                  <a className="newsText" target="_blank" href={newsItem.url}>
                    {newsItem.title}
                  </a>
                  <p>
                    <Moment fromNow>{newsItem.publishedAt}</Moment>
                  </p>
                  <p>{newsItem.content}</p>
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
      <hr />
      
      
        {reports.length === 0 ? (
          <hr/>
        ) : (
          <div className="container">    
          <div className="row" style={{ marginBottom: "300px" }}>
          <h1 style={{ textAlign: "center" }}>Reports</h1>
          <div className="col-12">
            <div className="row">
              {reports.reverse().map((i) => (
                <div className="col-md-6 col-xl-4">
                  <article className="post">
                    <div className="article-v2">
                      <figure className="article-thumb mb-10">
                        <a href={i.urlLink}>
                          <img
                            src={i.reportImage}
                            alt="blog image"
                            height="300px"
                          />
                        </a>
                      </figure>

                      <div className="article-content-main">
                        <div className="article-header">
                          <h2 className="entry-title">
                            <a href={i.urlLink}>{i.title}</a>
                          </h2>
                          <p>{i.summaryStatement}</p>
                          <div className="entry-meta">
                            <h6>
                              Posted on{" "}
                              <Moment format="MMM Do YYYY">
                                {i.datePublished}
                              </Moment>
                            </h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </article>
                </div>
              ))}
            </div>
          </div>
        </div>
        </div>
        )}

        

      {/*
  <NewsCard/>
   */}
    </>
  );
};

export default AgoraMap;
