import React, {useState, useEffect} from 'react'
import Accordion from 'react-bootstrap/Accordion';
import axios from 'axios'

function Manual() {
  const [data, setData] = useState([])  
  
  const fetchData = async () => {
    try {
      const res = await axios.get(
        "https://backend.africanagora.org/api/manual/"
         );
      setData(res?.data);
       } catch (err) {
      console.error(err);
    }
  };
  

  useEffect(() => {
    fetchData();
  }, []);



  return (

    
        <div>
            <h1 style={{textAlign:"center"}}>Directions</h1>
            <Accordion style={{textAlign:"left", fontSize:"25px", padding:"20px"}} defaultActiveKey="0">
          {
            data.map((i)=>
              <Accordion.Item eventKey="0" key={i?.id}>
            <Accordion.Header>{i?.question}</Accordion.Header>
            <Accordion.Body>
            {i.answer.map((listOfItems)=>(
                <ul key={listOfItems?.id}>
                <li>{listOfItems?.question_response}</li>
                
              </ul>
              ))}
  
                          
            </Accordion.Body>
          </Accordion.Item>
            )  
          }
          
          
        </Accordion>
        </div>
        
      );
    }

export default Manual