import React, {useState, useEffect} from 'react'
import axios from 'axios'
import Container from 'react-bootstrap/Container';
import './Header.css';
import Navbar from 'react-bootstrap/Navbar';

function Header() {
  const [headerList, setHeaderList] = useState([])

  const fetchHeaderList = async () => {
    try {
      const res = await axios.get('https://backend.africanagora.org/api/subheader/');
      console.log(res);
      setHeaderList(res.data);
      //setAllData(newsData.slice(0, 4))
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(()=>{
    fetchHeaderList()
  },[])
  return (
  
  <Navbar collapseOnSelect expand="lg" className='small-header'>
  <Container>
    {
      headerList.map((i)=>(<a key={i.id} href={i.urlLink} target='_blank' rel='noopener noreferrer'>
      {i.name} |
        </a>))
    }
      
  
     
    
  </Container>
</Navbar>
  )
}

export default Header