import React from 'react'
import { Link } from 'react-router-dom'
import ReactGA from 'react-ga';

const TRACKING_ID ="G-2D7RRR14SS";
ReactGA.initialize(TRACKING_ID);
ReactGA.pageview(window.location.pathname + window.location.search);


function AfricanAgora() {
  return (
<div>

  <div className="row">
  <div className="col-10">
    <h1 style={{textAlign:"center", fontFamily:"sans-serif", fontWeight:"bold", marginTop:"20px"}}>About the African Agora</h1>
    <p style={{marginLeft:"35px",marginTop:"30px", fontFamily:"sans-serif", fontSize:"18px"}}>The term Agora is originally from the Greek language, and it means the public place.
       The Agora served as an assembly area and a place for commercial, civic, social, and
        religious activities. It was the mainstay of democracy. During that era democracy
         was about direct representation unlike today’s representative democracy. Every 
         citizen had a direct say and also a vote on seemingly mundane issues. Currently
          democracy faces the threat of being privatized by a small group of actors who
           have the wherewithal to get elected and vote each other into different public
            offices. ​The African Agora project is part of attempts at reclaiming the public 
            space for citizen led initiatives. The barometer raises awareness on ongoing 
            government initiatives to impose limits or constraints on citizen led 
            initiatives through their NGOs, registered associations, and other 
            platforms of solidarity. We compare existing legal instruments and 
            policies enacted across ten SADC countries and how they affect citizens 
            and they organizations that they establish. ​</p>
            <p style={{textAlign:"center", fontFamily:"sans-serif", fontSize:"20px",marginBottom:"30px"}}>We measure the following:</p>
            
          <div className='container' style={{marginLeft:"35px"}}>
          <div className="row">
            
            <div className="card text-white mb-10" >
            <div className="card-header" id="africanAgoraCradHeader">Freedom of Assembly</div>
            <div className="card-body">
              <p className="card-text" id="africanAgoraCardText">The United Nations recognizes the importance of the rights to peaceful
               assembly and of association to the full enjoyment of civil and political rights, and
                economic, social, and cultural rights. Freedom of assembly ensures people can gather
                 and meet, both publicly and privately. Assemblies can be platforms to advocate for 
                 change and for people to raise awareness about the issues that matter to them,
                  whether it is human rights, socio-economic rights, or any other issue. We examine
                   existing legislation in all the countries to determine the extent to which they 
                   constrain/promote the freedom of assembly. </p>
          
            </div>
          </div>
          </div>
          <div className="row">
                      
                      <div className="card text-white mb-10">
                      <div className="card-header" id="africanAgoraCradHeader">Freedom of Expression</div>
                      <div className="card-body">
                        <p className="card-text" id="africanAgoraCardText">The right to express one's thoughts and to communicate freely with others affirms the dignity and worth of each and every member of society and allows each individual to realize his or her full human potential. According to the UN Charter (Article 10) everyone has the right to hold their own opinions and to express them freely without government interference. This includes the right to express your views aloud (for example through public protest and demonstrations) or through; (i) published articles, books or leaflets, (ii) television or radio broadcasting, (iii) works of art and (iv) the internet and social media. The law also protects one’s freedom to receive information from other people by, for example, being part of an audience or reading a magazine. We track the extent to which existing laws promote or inhibit the realization of the freedom of expression. We track the ways in which governments respond to protests, criticism and debate. ​</p>
                      </div>
                    </div>
                    </div>
                    <div className="row">
                      
                      <div className="card text-white mb-10">
                      <div className="card-header" id="africanAgoraCradHeader">Ease of Fundraising</div>
                      <div className="card-body">
                        <p className="card-text" id="africanAgoraCardText">Citizens actions especially within the organisations/associational forms that they establish are in most instances supported through Official Development Aid or Philanthropy. There have been attempts by governments across the globe to impose limitations on where NGOs can raise resources from. In some instances, governments have barred attempts at securing funding from out of country sources. We measure the impact of existing measures on NGO fundraising.​</p>
                      </div>
                    </div>
                    </div>
                    <div className="row">
                      
                      <div className="card text-white mb-10">
                      <div className="card-header" id="africanAgoraCradHeader">Ease of Moving Funds</div>
                      <div className="card-body">
                        <p className="card-text" id="africanAgoraCardText">Several NGOs (and related citizen led platforms) operate across borders. The challenges related to money laundering and suspicions of financing global terrorism have led government to impose strict measures against the movement funds from jurisdiction to the other. There is increased scrutiny on how NGOs move funds even within banks in one jurisdiction. The required paperwork at times increases the cost of doing business.  We measure the impact of existing measures on NGO operations.</p>
                      </div>
                    </div>
                    </div>
                    <div className="row">
                      
                      <div className="card text-white mb-10">
                      <div className="card-header" id="africanAgoraCradHeader">Ease of Registration</div>
                      <div className="card-body">
                        <p className="card-text" id="africanAgoraCardText">The region is characterized by different and uneven laws on how citizen established organisations/associations should formalize themselves. The most common form has been through the Deed of Trust. However, governments across the region are creating new regulations for registration and annual compliance measures. The barometer measures the impact of existing measures on ease of registration in terms of how long it takes to register, the different bureaucratic steps that one has to go through and the actual cost of registration</p>
                      </div>
                    </div>
                    </div>
                    <div className="row">
                      
                      <div className="card text-white mb-10">
                      <div className="card-header" id="africanAgoraCradHeader">Presence of Laws that Affect CSOs</div>
                      <div className="card-body">
                        <p className="card-text" id="africanAgoraCardText">There is an uneven spread of NGO/Societies focused laws within the sub-region. These laws usually provide guidelines on how NGOs and related platforms should operate. We measure their existence and the extent to which they constrain/promote the development of the NGO space. </p>
                      </div>
                    </div>
                    </div>
          </div>
  </div>
  <div className="col-2">
  <ul className="list-group" id="countryGroup" style={{height:"70vh"}}>
  <li className="list-group-item" id="countryList"><Link className='countryLink' to="/details/AGO">Angola</Link></li>
  <li className="list-group-item" id="countryList"><Link className='countryLink' to="/details/BWA">Botswana</Link></li>
  <li className="list-group-item" id="countryList"><Link className='countryLink' to="/details/SWZ">Eswatini</Link></li>
  <li className="list-group-item" id="countryList"><Link className='countryLink' to="/details/LSO">Lesotho</Link></li>
  <li className="list-group-item" id="countryList"><Link className='countryLink' to="/details/MDG">Madagascar</Link></li>
  <li className="list-group-item" id="countryList"><Link className='countryLink' to="/details/MWI">Malawi</Link></li>
  <li className="list-group-item" id="countryList"><Link className='countryLink' to="/details/MOZ">Mozambique</Link></li>
  <li className="list-group-item" id="countryList"><Link className='countryLink' to="/details/NAM">Namibia</Link></li>
  <li className="list-group-item" id="countryList"><Link className='countryLink' to="/details/ZAF">South Africa</Link></li>
  <li className="list-group-item" id="countryList"><Link className='countryLink' to="/details/TZA">United Republic of Tanzania</Link></li>
  <li className="list-group-item" id="countryList"><Link className='countryLink' to="/details/ZMB">Zambia</Link></li>
  <li className="list-group-item" id="countryList"><Link className='countryLink' to="/details/ZWE">Zimbabwe</Link></li>
</ul>
  </div>
</div>    
 <hr/>
    </div>
  )
}

export default AfricanAgora