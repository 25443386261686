import React from 'react';

export const Footer = () => {
  let date = new Date();

  return (
    <div className="container my-4">

<footer className="page-footer font-small pt-4 fixed-bottom">

 <div className="footer-copyright text-center py-3" style={{fontSize:"15px", fontWeight:"bold", backgroundColor:"#374C5B", color:"white"}}>&copy; {date.getFullYear()} Copyrights
    <a href="https://sivioinstitute.org/"> SIVIO Institute</a>
  </div>
</footer>



  </div>
  );
};
export default Footer;