import React from 'react'
import { Link } from "react-router-dom";

function CountrySideBar() {
  return (
    <div>
        <ul className="list-group" style={{ height: "70vh" }}>
            <li className="list-group-item" id="countryListMap">
              <Link className="countryLinkMap" to="/details/AGO">
                Angola
              </Link>
            </li>
            <li className="list-group-item" id="countryListMap">
              <Link className="countryLinkMap" to="/details/BWA">
                Botswana
              </Link>
            </li>
            <li className="list-group-item" id="countryListMap">
              <Link className="countryLinkMap" to="/details/SWZ">
                Eswatini
              </Link>
            </li>
            <li className="list-group-item" id="countryListMap">
              <Link className="countryLinkMap" to="/details/LSO">
                Lesotho
              </Link>
            </li>
            <li className="list-group-item" id="countryListMap">
              <Link className="countryLinkMap" to="/details/MDG">
                Madagascar
              </Link>
            </li>
            <li className="list-group-item" id="countryListMap">
              <Link className="countryLinkMap" to="/details/MWI">
                Malawi
              </Link>
            </li>
            <li className="list-group-item" id="countryListMap">
              <Link className="countryLinkMap" to="/details/MOZ">
                Mozambique
              </Link>
            </li>
            <li className="list-group-item" id="countryListMap">
              <Link className="countryLinkMap" to="/details/NAM">
                Namibia
              </Link>
            </li>
            <li className="list-group-item" id="countryListMap">
              <Link className="countryLinkMap" to="/details/ZAF">
                South Africa
              </Link>
            </li>
            <li className="list-group-item" id="countryListMap">
              <Link className="countryLinkMap" to="/details/TZA">
                United Republic of Tanzania
              </Link>
            </li>
            <li className="list-group-item" id="countryListMap">
              <Link className="countryLinkMap" to="/details/ZMB">
                Zambia
              </Link>
            </li>
            <li className="list-group-item" id="countryListMap">
              <Link className="countryLinkMap" to="/details/ZWE">
                Zimbabwe
              </Link>
            </li>
          </ul>

    </div>
  )
}

export default CountrySideBar