import React from "react";

function KeyCard() {
  
  return (
    <div className="card">
  <div className="card-header">
    <h2>KEY</h2>
  </div>

  <ul className="list-group list-group-flush">
    <li className="list-group-item d-inline-block" tabIndex="0" data-toggle="tooltip" title="the state both enables and safeguards the enjoyment of civic space for all people. Levels of fear are low as citizens are free to form associations, demonstrate in public places and receive and impart information without restrictions in law or practice​" style={{backgroundColor:"#7FBC41", fontFamily:"sans-serif", fontSize:"14px"}}>Open</li>
    <li className="list-group-item d-inline-block" tabIndex="0" data-toggle="tooltip" title="while the state allows individuals and civil society organisations to exercise their rights to freedom of association, peaceful assembly and expression, violations of these rights also take place​" style={{backgroundColor:"#BEDE9E", fontFamily:"sans-serif", fontSize:"14px"}}>Narrow</li>
    <li className="list-group-item d-inline-block" tabIndex="0" data-toggle="tooltip" title="civic space is heavily contested by power holders, who impose a combination of legal and practical constraints on the full enjoyment of fundamental rights. Although civil society organisations exist, state authorities undermine them​​" style={{backgroundColor:"#F15B26", fontFamily:"sans-serif", fontSize:"14px"}}>Obstructed</li>
    <li className="list-group-item d-inline-block" tabIndex="0" data-toggle="tooltip" title="civic space is heavily constrained. Active individuals and civil society members who criticise power holders risk surveillance, harassment, intimidation, imprisonment, injury and death​​" style={{backgroundColor:"#FC8D3A",fontFamily:"sans-serif", fontSize:"14px"}}>Repressed</li>
    <li className="list-group-item d-inline-block" tabIndex="0" data-toggle="tooltip" title="​there is complete closure – in law and in practice – of civic space. An atmosphere of fear and violence prevails, any criticism of the ruling authorities is severely punished, and there is virtually no media freedom​" style={{backgroundColor:"#FF0000", fontFamily:"sans-serif", fontSize:"14px"}}>Closed</li>
  </ul>
</div>
  )
}

export default KeyCard