import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import Chart from "react-apexcharts";
import { Card, Row, Col } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";
import ReactGA from 'react-ga';
import KeyCard from "../components/KeyCard";

const TRACKING_ID ="G-2D7RRR14SS";
ReactGA.initialize(TRACKING_ID);
ReactGA.pageview(window.location.pathname + window.location.search);

function CountryDetails() {
  let { id } = useParams();

  const [dataList, setDataList] = useState([]);
  let otherLaws = [];
  let laws = [];
  let myArray = [];
  const finalArray = [];
  let searchObject = [];
  const [cardData, setCardData] = useState({});
  let [result, setResult] = useState("");

  const fetchData = async () => {
    try {
      const res = await axios.get(
        "https://backend.africanagora.org/api/nation/"
        //"https://backend.africanagora.org/api/sadc/"
      );
      setDataList(res?.data);
      myArray = res?.data;
      searchObject = myArray.find((country) => country.ISO_A3 === id);
      //otherLaws.push(searchObject.otherLaws);
      setCardData(searchObject);
      finalArray.push(searchObject.Presence_of_Laws_that_affect_CSOs);
      finalArray.push(searchObject.Ease_of_Registration);
      finalArray.push(searchObject.Ease_of_fundraising_from_external_sources);
      finalArray.push(searchObject.Ease_of_Moving_Funds);
      finalArray.push(searchObject.Freedom_of_Assembly);
      finalArray.push(searchObject.Freedom_of_Expression);
      //let status = searchObject.totalScore;
     // let status = searchObject.averageScore;
     let tot = (Number(searchObject.Ease_of_Moving_Funds)+Number(searchObject.Ease_of_Registration)+Number(searchObject.Ease_of_fundraising_from_external_sources)+Number(searchObject.Freedom_of_Assembly)+Number(searchObject.Freedom_of_Expression)+Number(searchObject.Presence_of_Laws_that_affect_CSOs))
     let status = Math.round(tot/6)
      
      let result =
        status === 1
          ? "Open"
          : status === 2
          ? "Narrow"
          : status === 3
          ? "Obstructed"
          : status === 4
          ? "Repressed"
          : "Closed";
      setResult(result);
      setSeries([
        {
          name: "",
          data: finalArray,
        },
      ]);
    } catch (err) {
      //history('/')
      console.error(err);
    }
  };
  

  useEffect(() => {
    fetchData();
  }, []);

  

  const [series, setSeries] = useState([
    {
      name: "",
      data: [],
    },
  ]);

  let options = {
    
    chart: {
      type: "bar",
      height: 350,
    },
    colors: [
      function({ value, seriesIndex, w }) {
        if (value===1) {
          return '#7FBC41'}
else if(value===2){
  return '#BEDE9E'
}else if(value===3){
  return '#F15B26'
}else if(value===4){
  return '#FC8D3A'
} else {
          return '#FF0000'
        }
      }
    ],
    
    plotOptions: {
      bar: {
        borderRadius: 4,
        horizontal: true,
      },
    },
    dataLabels: {
      enabled: true,
    },
    yaxis: {
      labels: {
        style: {
          colors: [],
          fontSize: "15px",
          fontFamily: "Helvetica, Arial, sans-serif",
          fontWeight: 400,
          cssClass: "apexcharts-xaxis-label",
        },
      },
    },
    xaxis: {
      type: "category",

      labels: {
        show: true,

        showDuplicates: false,
        trim: true,
        maxHeight: 120,
        style: {
          colors: [],
          fontSize: "15px",
          fontFamily: "Helvetica, Arial, sans-serif",
          fontWeight: 400,
          cssClass: "apexcharts-xaxis-label",
        },
      },

      categories: [
        ["Presence of Laws", "that affect CSOs"],
        ["Ease of", " Registration"],
        ["Ease of", "fundraising from", "external sources"],
        ["Ease of", " Moving Funds"],
        ["Freedom of", " Assembly"],
        ["Freedom of", " Expression"],
        
      ],
    },
  };

  return (
    <div>
      <Row>
        <Col>
          {
            <Card col-lg-4 col-xs-12 style={{ margin: "20px" }} key={cardData?.id}>
              <img
                width={125}
                className="centered"
                style={{ marginLeft: "20px", marginTop: "20px" }}
                src={cardData?.flag}
                alt="country flag"
              ></img>
              <Card.Body className="countryDetailCard flex">
                <span style={{ fontFamily: "sans-serif", fontSize: "20px" }}>
                  Country:
                </span>
                <strong style={{ fontFamily: "sans-serif", fontSize: "21px" }}>
                  {" "}
                  {cardData?.ADMIN}
                </strong>
                <br></br>
                <span style={{ fontFamily: "sans-serif", fontSize: "20px" }}>
                  Category:
                </span>
                <strong style={{ fontFamily: "sans-serif", fontSize: "20px" }}>
                  {" "}
                  {result}
                </strong>
                <br></br>
                <span style={{ fontFamily: "sans-serif", fontSize: "20px" }}>
                  Primary Law :
                </span>{" "}
                <Accordion defaultActiveKey={["0"]} alwaysOpen>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                      {cardData?.Primary_Laws_Affecting_CSOS}
                    </Accordion.Header>
                    <Accordion.Body
                      style={{
                        fontFamily: "sans-serif",
                        fontSize: "18px",
                        marginLeft: "20px",
                      }}
                    >
                      {cardData?.FeaturesOfTheLaw}
                      <br></br>

                      <li style={{cursor:"pointer"}}>
                        <a href={cardData?.primaryLawLink} style={{cursor:"pointer"}} target='_blank' rel='noopener noreferrer'>CLICK HERE TO READ THE LAW</a>
                      </li>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
                <br></br>
                <span style={{ fontFamily: "sans-serif", fontSize: "20px" }}>
                  Other Laws that affect CSOs :
                </span>
                     
                      {cardData?.otherLaws?.length === 0 ? 
        (<p>N/A</p>)
       : (
        <div >
          <ul className="list-group">{
                            
                            (cardData?.otherLaws?.map((i)=>(
                              
                              <li className="list-group-item" tabIndex="0" data-toggle="tooltip" title={i?.summaryStatement}><a style={{cursor:"pointer"}} href={i.urlLink} target="_blank" rel="noopener noreferrer" tabIndex="0" data-toggle="tooltip" title={i.summaryStatement}>Click to Read {i.secondaryLaw}</a></li>
                            )))
        }
                        </ul> 
        </div>
      )}
                  
                      
                      
                     
                          
                      
                      
                        

                <br></br>
              </Card.Body>
            </Card>
          }
          <div style={{ fontWeight: "bolder", width:"12rem", marginTop:"40px", marginLeft:"20px"}}>
          <KeyCard/>
          </div>
        </Col>
        <div className="col-lg-6 col-xs-12" style={{ margin: "20px" }}>
          <div style={{ marginLeft: "20px" }}>
            <h2>Operating Environment</h2>
          </div>
          <p
            style={{
              fontFamily: "sans-serif",
              fontSize: "18px",
              margin: "20px",
            }}
          >
            {cardData?.summaryStatement}
          </p>

          <Chart height={500} options={options} series={series} type="bar" style={{marginBottom:"500px"}} />
        </div>
        <div className="col-lg-2 col-xs-12">
          <ul className="list-group" id="countryGroup" style={{ height: "70vh" }}>
            <li className="list-group-item" id="countryList">
              <a className="countryLink" href="/details/AGO">
                Angola
              </a>
            </li>
            <li className="list-group-item" id="countryList">
              <a className="countryLink" href="/details/BWA">
                Botswana
              </a>
            </li>
            <li className="list-group-item" id="countryList">
              <a className="countryLink" href="/details/SWZ">
                Eswatini
              </a>
            </li>
            <li className="list-group-item" id="countryList">
              <a className="countryLink" href="/details/LSO">
                Lesotho
              </a>
            </li>
            <li className="list-group-item" id="countryList">
              <a className="countryLink" href="/details/MDG">
                Madagascar
              </a>
            </li>
            <li className="list-group-item" id="countryList">
              <a className="countryLink" href="/details/MWI">
                Malawi
              </a>
            </li>
            <li className="list-group-item" id="countryList">
              <a className="countryLink" href="/details/MOZ">
                Mozambique
              </a>
            </li>
            <li className="list-group-item" id="countryList">
              <a className="countryLink" href="/details/NAM">
                Namibia
              </a>
            </li>
            <li className="list-group-item" id="countryList">
              <a className="countryLink" href="/details/ZAF">
                South Africa
              </a>
            </li>
            <li className="list-group-item" id="countryList">
              <a className="countryLink" href="/details/TZA">
                United Republic of Tanzania
              </a>
            </li>
            <li className="list-group-item" id="countryList">
              <a className="countryLink" href="/details/ZMB">
                Zambia
              </a>
            </li>
            <li className="list-group-item" id="countryList">
              <a className="countryLink" href="/details/ZWE">
                Zimbabwe
              </a>
            </li>
          </ul>
        </div>
      </Row>
    </div>
  );
}

export default CountryDetails;
