import React from "react";
import "./App.css";
import Agora from "./components/Agora";
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import CountryDetails from "./pages/CountryDetails";
import Footer from "./components/Footer";
import AfricanAgora from "./pages/AfricanAgora";
import EaseOfRegistration from "./pages/EaseOfRegistration";
import EaseOfMovingFunds from "./pages/EaseOfMovingFunds";
import FreedomOfAssembly from "./pages/FreedomOfAssembly";
import FreedomOfExpression from "./pages/FreedomOfExpression";
import PresenceOfLawsThatAffectCSOs from "./pages/PresenceOfLawsThatAffectCSOs";
import EaseOfFundRaising from "./pages/EaseOfFundRaising";
import Header from "./components/Header";
import ReactGA from 'react-ga';
import NavTailwind from './components/NavTailwind'
import Manual from "./components/Manual";

const TRACKING_ID ="G-2D7RRR14SS";
ReactGA.initialize(TRACKING_ID);
ReactGA.pageview(window.location.pathname + window.location.search);

function App() {
  return (
    <div>

<BrowserRouter>
<div className="header-bg">
  
<Header/>
<NavTailwind/>
</div>
        <Routes>
          <Route path="/" element={<Agora />} />
          <Route path="/details/:id" element={<CountryDetails />} />
          <Route path="/african-agora" element={<AfricanAgora />} />
          <Route path="/ease-of-registration" element={<EaseOfRegistration />} />
          <Route path="/ease-of-moving-funds" element={<EaseOfMovingFunds />} />
          <Route path="/freedom-of-assembly" element={<FreedomOfAssembly />} />
          <Route path="/freedom-of-expression" element={<FreedomOfExpression />} />
          <Route path="/presence-of-laws-that-affect-cso" element={<PresenceOfLawsThatAffectCSOs />} />
          <Route path="/ease-of-registration" element={<EaseOfRegistration />} />
          <Route path="/ease-of-fundraising" element={<EaseOfFundRaising />} />
          <Route path="/navigation" element={<Manual />} />
          <Route path="*" element={<Agora/>} />
        </Routes>
  
    </BrowserRouter>
<Footer />
      
    </div>
  );
  
}

export default App;
