import React, { useState, useEffect } from "react";

import Loading from "./Loading";
import AgoraMap from "./AgoraMap";
import LoadCountriesTask from "../tasks/LoadCountriesTask";
//import Legend from "./Legend";
import legendItems from "../entities/LegendItems";



const Agora = () => {
  const [countries, setCountries] = useState([]);


//  const legendItemsReverse = [...legendItems].reverse();

  const load = () => {
    
    const loadCountriesTask = new LoadCountriesTask();
    loadCountriesTask.load((countries) => setCountries(countries));
  };
  //console.log(countries)

  useEffect(load, []);

  return (
    <div>
      {countries.length === 0 ? (
        <Loading />
      ) : (
        <div >
          
          <AgoraMap countries={countries} />
          
          
          
        </div>
      )}
    </div>
  );
};

export default Agora;

/*
className Covid19 extends Component {
  state = {
    countries: [],
  };

  loadCountryTask = new LoadCountryTask();

  componentDidMount() {
    this.loadCountryTask.load((countries) => this.setState({ countries }));
  }

  render() {
    const { countries } = this.state;
    return (
      <div>
        {countries.length === 0 ? (
          <Loading />
        ) : (
          <div>
            <CovidMap countries={countries} />
            <Legend legendItems={legendItems} />
          </div>
        )}
      </div>
    );
  }
}

export default Covid19;
*/
