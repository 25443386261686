import LegendItem from "./LengendItem";

var AssemblylegendItems = [
  
  new LegendItem(
    "CLOSED",
    //"25 - 30",
    "#FF0000",
    (cases) => cases===5
  ),

  new LegendItem(
    //"19 - 24",
    "REPRESSED",
    "#FC8D3A",
    (cases) => cases===4,
    "aqua"
  ),

  new LegendItem(
    //"13 - 18",
    "OBSTRUCTED",
    "#F15B26",
    (cases) => cases===3 ,
    "aqua"
  ),

  new LegendItem(
    "NARROW",
    //"7 - 12",
    "#BEDE9E",
    (cases) => cases===2
  ),

  new LegendItem(
    //"6",
    "OPEN",
    "#7FBC41",
    (cases) => cases===1
  ),

  new LegendItem("No Data", "#ffffff", (cases) => true),
];

export default AssemblylegendItems;

