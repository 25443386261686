import React, { useState, useEffect } from "react";
import Loading from "../components/Loading";
import MovingFundsMap from "../components/MovingFundsMap";
import LoadCountriesTaskMovingFunds from "../tasks/LoadCountriesTaskMovingFunds";
//import MovingFundslegendItems from "../entities/MovingFundsLegendItems";
import ReactGA from 'react-ga';

const TRACKING_ID ="G-2D7RRR14SS";
ReactGA.initialize(TRACKING_ID);
ReactGA.pageview(window.location.pathname + window.location.search);


function EaseOfMovingFunds() {
  const [countries, setCountries] = useState([]);

  //const legendItemsReverse = [...legendItems].reverse();

  const load = () => {
    console.log("load");
    const loadCountriesTask = new LoadCountriesTaskMovingFunds();
    loadCountriesTask.load((countries) => setCountries(countries));
  };
  console.log(countries)

  useEffect(load, []);

  return (
    <div>
      {countries.length === 0 ? (
        <Loading />
      ) : (
        <div >
          <MovingFundsMap
 countries={countries} />
        </div>
      )}
    </div>
  );
};


export default EaseOfMovingFunds