import React from "react";
import { Map, GeoJSON } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import "./AgoraMap.css";
import { Link } from "react-router-dom";
import KeyCard from "./KeyCard";
import CountrySideBar from "./CountrySideBar";

const RegistrationMap = ({ countries }) => {
  const mapStyle = {
    weight: 1,
    color: "black",
    fillOpacity: 1,
  };

  const onEachCountry = (country, layer) => {
    layer.options.fillColor = country.properties.color;

const name = country.properties.ADMIN;
const presence = country.properties.Presence_of_Laws_that_affect_CSOs;
  const easeOfMovingFunds = country.properties.Ease_of_Moving_Funds;
    const easeOfRegistration = country.properties.Ease_of_Registration;
    const easeOfFundraising = country.properties.Ease_of_fundraising_from_external_sources;
    const freedomOfExpression = country.properties.Freedom_of_Expression;
    const freedomOfAssembly = country.properties.Freedom_of_Assembly;
    const totalScore = presence+easeOfMovingFunds+easeOfRegistration+easeOfFundraising+freedomOfExpression+freedomOfAssembly;
    const averageScore = Math.round(totalScore/6);
    
    layer.bindTooltip(`<h1 style='margin-top:10px'>Country: ${name}</h1>
    <h3>Overall Category: ${averageScore===1?"Open":averageScore===2?"Narrow":averageScore===3?"Obstructed":averageScore===4?"Repressed":"Closed"} </h3>
    <h6>Ease of Moving Funds: ${easeOfMovingFunds===1?"Open":easeOfMovingFunds===2?"Narrow":easeOfMovingFunds===3?"Obstructed":easeOfMovingFunds===4?"Repressed":"Closed"}</h6>
    <p>Presence of Laws that affect CSOs: ${presence===1?"Open":presence===2?"Narrow":presence===3?"Obstructed":presence===4?"Repressed":"Closed"}</p>
      <p>Ease of Registration: ${easeOfRegistration===1?"Open":easeOfRegistration===2?"Narrow":easeOfRegistration===3?"Obstructed":easeOfRegistration===4?"Repressed":"Closed"}</p>
      <p>Ease of Fundraising from External Sources: ${easeOfFundraising===1?"Open":easeOfFundraising===2?"Narrow":easeOfFundraising===3?"Obstructed":easeOfFundraising===4?"Repressed":"Closed"}</p>
      <p>Freedom of Assembly: ${freedomOfAssembly===1?"Open":freedomOfAssembly===2?"Narrow":freedomOfAssembly===3?"Obstructed":freedomOfAssembly===4?"Repressed":"Closed"}</p> 
      <p>Freedom of Expression: ${freedomOfExpression===1?"Open":freedomOfExpression===2?"Narrow":freedomOfExpression===3?"Obstructed":freedomOfExpression===4?"Repressed":"Closed"}</p>
      `, { permanent: false, offset: [0, 12] });
//layer.bindPopup(`${name} ${presence}`);

};

  const onNavigateCountry = (id) => {
    //console.log(id.layer.feature.properties.ISO_A3)
    window.location = `details/${id.layer.feature.properties.ISO_A3}`;
  };


  return (
    <>
      <div className="row">
        <nav
          aria-label="breadcrumb"
          className="col-lg-12 col-xs-12 col-centered"
        >
          <ol
            className="breadcrumb"
            style={{ width: "800px", backgroundColor: "white" }}
          >
            <li
              className="breadcrumb-item active"
              style={{ fontSize: "24px", fontWeight: "bold" }}
              aria-current="page"
            >
              <Link className="breadCrumb" to="/">
                Home
              </Link>{" "}
              / Ease of Registration
            </li>
          </ol>
        </nav>
        <div className="col-lg-6 col-xs-12 col-centered">
          <Map
            style={{ height: "140vh" }}
            zoom={3.5}
            center={[-35, 28]}
            dragging={false}
          >
            <GeoJSON
              style={mapStyle}
              data={countries}
              onEachFeature={onEachCountry}
              onclick={onNavigateCountry}
            />
          </Map>
        </div>

        <div className="col-lg-3 col-xs-12">
          <div style={{ marginTop: "5px", float: "right" }}>
            <ul className="list-group list-group-flush">
              <li
                className="list-group-item"
                style={{
                  backgroundColor: "white",
                  fontFamily: "sans-serif",
                  fontSize: "15px",
                }}
              >
                We measures the impact of existing measures on ease of
                registration in terms of how long it takes to register, the
                different bureaucratic steps that one has to go through and the
                actual cost of registration.
              </li>

              <Link
                className="btn"
                style={{
                  backgroundColor: "#374C5B",
                  color: "white important",
                  fontSize: "20px",
                }}
                to="/african-agora"
              >
                Read more
              </Link>
            </ul>
            <div className="key">
              <KeyCard />
            </div>
          </div>
        </div>

        <div className="col-lg-3 col-xs-12" style={{ marginTop: "-45px" }}>
        <CountrySideBar/>
        </div>
      </div>
    </>
  );
};

export default RegistrationMap;
