import LegendItem from "./LengendItem";

var legendItems = [
  
  new LegendItem(
    "CLOSED",
    //"25 - 30",
    "#FF0000",
    (cases) => cases ===5
  ),

  new LegendItem(
    //"19 - 24",
    "REPRESSED",
    "#FC8D3A",
    (cases) => cases ===4,
    "aqua"
  ),

  new LegendItem(
    //"13 - 18",
    "OBSTRUCTED",
    "#F15B26",
    (cases) => cases===3 ,
    "aqua"
  ),

  new LegendItem(
    "NARROW",
    //"7 - 12",
    "#BEDE9E",
    (cases) => cases ===2
  ),

  new LegendItem(
    //"6",
    "OPEN",
    "#7FBC41",
    (cases) => cases ===1
  ),

  new LegendItem("No Data", "#ffffff", (cases) => true),
];

export default legendItems;

/**
 * 7 > 1 million                        #8b0000
 * 6 >= 500 thousand < 1 million        #9e2a2a
 * 5 >= 200 thousand < 500 thousand     #b15555
 * 4 >= 100 thousand  < 200 Thousand    #c57f7f
 * 3 > 50 thousand < 100 thousand       #d8aaaa
 * 2 >= 0 < 50 thousand                 #ebd4d4
 * 1 NO DATA                            #ffffff
 */

/*

#741f1f // Really red
#9c2929 // more red
#c57f7f // red
#d8aaaa //more pink
#ebd4d4 //pink
#ffffff //white
*/
